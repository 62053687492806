import React from "react";
import Head from "next/head";
import PropTypes from "prop-types";
import { LANGUAGES } from "@buddieshr/utils/constants";

const MetaHeader = ({
  title,
  description,
  currentURL = "https://buddieshr.com",
  currentShare = "https://buddieshr.com/img/share_preview_1.png",
  isArticle = false,
  pageSlug = "",
  locale = "en",
}) => {
  const currentURLWithoutEn =
    locale === "en" ? `https://buddieshr.com/${pageSlug}` : currentURL;
  return (
    <Head>
      <title>{title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1,shrink-to-fit=no"
      />
      <meta name="description" content={description} itemProp="description" />
      {/* <!-- Schema.org meta for Google+ --> */}
      <meta itemProp="name" content={title} />
      <link rel="canonical" href={currentURLWithoutEn} />
      <link
        rel="alternate"
        hrefLang={"en"}
        href={`https://buddieshr.com/${pageSlug}`}
        key={"en"}
      />
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`https://buddieshr.com/${pageSlug}`}
        key={"default"}
      />
      {LANGUAGES.filter((l) => l !== "en").map((lang) => (
        <link
          rel="alternate"
          hrefLang={lang}
          href={`https://buddieshr.com/${lang}${
            pageSlug ? `/${pageSlug}` : ""
          }`}
          key={lang}
        />
      ))}
      <meta itemProp="image" name="image" content={currentShare} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={currentURLWithoutEn} />
      <meta name="twitter:image" content={currentShare} />
      {/* <!-- Open Graph meta --> */}
      <meta property="og:title" content={title} />
      <meta property="og:url" content={currentURLWithoutEn} />
      <meta property="og:image" content={currentShare} />

      <meta property="og:description" content={description} />
      {isArticle ? (
        <>
          <meta property="og:type" content="article" />
          <meta
            property="article:published_time"
            content="2024-01-04T22:51:13+00:00"
          />
          <meta
            property="article:modified_time"
            content="2024-01-04T22:51:13+00:00"
          />
          <meta property="article:author" content="Buddies HR" />
          <meta property="article:publisher" content="Buddies HR" />
        </>
      ) : (
        <meta property="og:type" content="website" />
      )}
    </Head>
  );
};

export default MetaHeader;
