import styles from '@buddieshr/styles/components/headerApp.module.scss';
import Link from 'next/link';
import {
  showMarketingBannerHRDay,
  showMarketingBannerInternationHRDay,
  useWindowSize,
} from '@buddieshr/utils/utils';
import { Button, Menu } from 'antd';
import LogoApp from './app/logoApp';
import MobileMenuApp from './mobileMenu';
import { PRODUCTS } from '@buddieshr/utils/constants-products';
import LogoBuddiesHR from './logoBuddies';
import { useEffect, useState } from 'react';
import {
  CalculatorOutlined,
  CoffeeOutlined,
  CompassOutlined,
  CrownOutlined,
  DownOutlined,
  ExportOutlined,
  FormOutlined,
  LaptopOutlined,
  MessageOutlined,
  SmileOutlined,
} from '@ant-design/icons';

const Header = ({ app }) => {
  const size = useWindowSize();
  const [mobile, setMobile] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const showMarketingInternationalHRDay = showMarketingBannerInternationHRDay();
  const showMarketingHRDay = showMarketingBannerHRDay();
  const showMarketing = showMarketingInternationalHRDay || showMarketingHRDay;

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    setMobile(size?.width && size?.width < 850);
  }, [size]);

  const freeToolsList = [
    {
      label: (
        <Link
          href="/free-work-anniversary-message-generator"
          title={'Work Anniversary Message Generator'}
        >
          Work Anniversary message generator
        </Link>
      ),
      key: 'workannivgenerator',
    },
    {
      label: (
        <Link href="/cost-of-turnover" title={'True cost of turnover'}>
          Turnover cost calculator
        </Link>
      ),
      key: 'turnovercalc',
    },
    {
      label: (
        <Link
          href="/pto-accrual-calculator"
          title={'free pto accrual calculator'}
        >
          PTO accrual calculator
        </Link>
      ),
      key: 'pto',
    },
    {
      label: (
        <Link href="/hr-policies" title={'Human Resources policies examples'}>
          HR policies - 300+ examples
        </Link>
      ),
      key: 'hrpolicies',
    },
    {
      label: (
        <Link href="/hr-remote-jobs" title={'Human Resources remote job board'}>
          HR remote job board
        </Link>
      ),
      key: 'hrremote',
    },
    {
      label: (
        <Link
          href="/virtual-team-building-remote-teams"
          title={'virtual games and team building activities'}
        >
          Virtual games and team building activities
        </Link>
      ),
      key: 'remotegames',
    },
  ];
  const items = [
    {
      label: (
        <>
          Solutions <DownOutlined className={styles.iconMenu} />
        </>
      ),
      key: 'Solutions',
      children: [
        ...PRODUCTS.filter((p) => p.show).reduce(
          (acc, element) => [
            ...acc,
            {
              key: element.slug,
              className: styles.elementEE,
              label: (
                <div className={styles.logo}>
                  <Link
                    href={
                      app?.slug === element.slug
                        ? '#'
                        : element.localhref || element.href
                    }
                    style={{ textDecoration: 'none' }}
                    alt={`${element?.title} - slack`}
                  >
                    <LogoApp
                      app={element}
                      removeBack
                      withText
                      withSubtitle
                      removeByBuddies
                    />
                  </Link>
                </div>
              ),
            },
          ],
          []
        ),
        {
          key: 'bud',
          className: styles.elementEEBud,
          label: (
            <div className={styles.logo}>
              <Link
                href={'/'}
                style={{ textDecoration: 'none', width: '100%' }}
                title={'employee engagement app'}
              >
                <LogoApp
                  wrapperClassName={styles.buddiesHeader}
                  app={{
                    logoUrlWithBackground: '/img/logo/logo.png',
                    logoUrl: '/img/logo/logo.png',
                    fullName: 'BuddiesHR',
                    shortTitle: 'All in one employee engagement software',
                  }}
                  removeBack
                  withText
                  withSubtitle
                  removeByBuddies
                  white
                />
              </Link>
            </div>
          ),
        },
      ],
    },
    {
      label: (
        <>
          Use Cases <DownOutlined className={styles.iconMenu} />
        </>
      ),
      key: 'usecases',
      children: [
        {
          label: (
            <Link
              href={`/use-cases/celebrations`}
              title={'Celebrate milestones as a team'}
            >
              Team celebrations
            </Link>
          ),
          key: 'celebrations',
          icon: <CrownOutlined />,
        },
        {
          label: (
            <Link
              href={`/use-cases/recognition`}
              title={'recognize employees in slack'}
            >
              Employee recognition
            </Link>
          ),
          key: 'recognitions',
          icon: <SmileOutlined />,
        },
        // {
        //   label: "Employee onboarding",
        //   key: "onboarding",
        // },
        {
          label: (
            <Link
              href={`/use-cases/company-belonging`}
              title={'boosts company belonging'}
            >
              Company belonging
            </Link>
          ),
          key: 'belonging',
          icon: <CompassOutlined />,
        },
        {
          label: (
            <Link
              href={`/use-cases/feedback`}
              title={'gather employee feedback in slack'}
            >
              Gather employee feedback
            </Link>
          ),
          key: 'feedback',
          icon: <FormOutlined />,
        },
        {
          label: (
            <Link
              href={`/use-cases/remote`}
              title={'slack for remote companies'}
            >
              Remote company
            </Link>
          ),
          key: 'remote',
          icon: <LaptopOutlined />,
        },
        {
          label: (
            <Link
              href={`/use-cases/measure-engagement`}
              title={'how to measure employee engagement in slack'}
            >
              Measuring engagement
            </Link>
          ),
          key: 'measure',
          icon: <CalculatorOutlined />,
        },
        {
          label: (
            <Link
              href={`/use-cases/standup`}
              title={'Run async standup meetings in slack'}
            >
              Standup Meetings
            </Link>
          ),
          key: 'standup',
          icon: <CoffeeOutlined />,
        },
        {
          label: (
            <Link
              href={`/use-cases/virtual-watercooler`}
              title={"what's the best virtual watercooler"}
            >
              Virtual Watercooler
            </Link>
          ),
          key: 'watercooler',
          icon: <MessageOutlined />,
        },
        {
          type: 'group',
          label: 'Buddies is for you',
          children: [
            {
              label: (
                <Link
                  href={`/use-cases/human-resources`}
                  title={'Engagement tool for hrs'}
                >
                  For Human Resources
                </Link>
              ),
              key: 'hr',
            },

            // {
            //   label: (
            //     <Link
            //       href={`/billy/startup`}
            //       title={"Engagement tool for founders"}
            //     >
            //       For Founders
            //     </Link>
            //   ),
            //   key: "founders",
            // },
          ],
        },
      ],
    },
    {
      key: 'Pricing',
      label: (
        <Link href={'/pricing'} title={'pricing for culture platform'}>
          Pricing
        </Link>
      ),
    },
    {
      label: (
        <span>
          Resources <DownOutlined className={styles.iconMenu} />
        </span>
      ),
      key: 'Resources',
      children: [
        {
          label: (
            <Link href="/reviews" title={'Reviews and feedback on Buddieshr'}>
              Customer Reviews ⭐️
            </Link>
          ),
          key: 'reviews',
        },
        {
          label: `Free tools (${freeToolsList.length})`,
          children: freeToolsList,
        },
        {
          label: (
            <Link
              href="https://blog.buddieshr.com/"
              title={'improve work experience'}
              target={'_blank'}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Blog&nbsp;&nbsp;
                <ExportOutlined style={{ fontSize: 12, marginBottom: 2 }} />
              </div>
            </Link>
          ),
          key: 'blog',
        },
        {
          label: (
            <Link
              href={`/docs/${app ? `${app.slug}` : ''}`}
              title={'Help, Q&A & video tutorials BuddiesHR'}
            >
              Help & Video tutorials
            </Link>
          ),
          key: 'doc',
        },

        {
          label: (
            <Link
              href="/startup-culture-insights-report-2024"
              title={'Startup Culture Insights Report [2024 edition]'}
            >
              Startup Culture Report 2024
            </Link>
          ),
          key: 'culturereport',
        },
        {
          label: (
            <Link href="/best-slack-apps" title={'Best slack applications'}>
              Best Apps for Slack
            </Link>
          ),
          key: 'bestslackapps',
        },

        {
          label: 'Alternatives',
          children: [
            {
              label: (
                <Link
                  href="/alternatives/donut-randomcoffee-alfy"
                  title={
                    'alternatives virtual coffee chat for slack:Donut versus RandomCoffee versus Alfy'
                  }
                >
                  Donut versus RandomCoffee versus Alfy
                </Link>
              ),
              key: 'alternatives random coffee apps for slack',
            },
            {
              label: (
                <Link
                  href="/alternatives/simplepoll-polly-pulsy"
                  title={
                    'alternatives polls and surveys for slack: SimplePoll versus Polly versus Pulsy'
                  }
                >
                  SimplePoll versus Polly versus Pulsy
                </Link>
              ),
              key: 'alternatives polls and surveys for slack',
            },
            {
              label: (
                <Link
                  href="/alternatives/heytaco-kudosing-clappy"
                  title={
                    'alternatives kudos for slack: Heytaco versus Kudosing versus Clappy'
                  }
                >
                  Heytaco versus Kudosing versus Clappy
                </Link>
              ),
              key: 'compare recognition apps for slack',
            },
            {
              label: (
                <Link
                  href="/alternatives/birthdaybot-culturebot-billy"
                  title={
                    'alternatives birthday apps for slack: birthdaybot versus culturebot versus billy birthday'
                  }
                >
                  Birthdaybot versus Culturebot versus Billy
                </Link>
              ),
              key: 'compare birthday and workiversary apps for slack',
            },
            {
              label: (
                <Link
                  href="/alternatives/donut"
                  title={'alternatives Donut for slack'}
                >
                  Donut versus BuddiesHR
                </Link>
              ),
              key: 'compare donut and buddies',
            },
          ],
        },
      ],
    },
  ];

  return (
    <header
      className={`${styles.wrapper} ${
        scrollPosition > 5 ? ` ${styles.sticky}` : ''
      }`}
      style={{
        background: app ? app.backgroundColor : 'white',
        paddingTop: showMarketing ? 44 : 0,
        height: showMarketing ? 114 : 70,
      }}
    >
      <div className={styles.inner}>
        <div className={styles.left}>
          <div className={styles.logo}>
            <Link
              href="/"
              title={'Buddies HR , culture slack apps for remote companies'}
            >
              <LogoBuddiesHR />
            </Link>
          </div>
        </div>
        <div className={styles.right}>
          {!mobile && (
            <>
              <Menu
                mode="horizontal"
                items={items}
                className={styles.menuHorizontal}
                // forceSubMenuRender={true}
              />
              <Link
                href={'/get-started'}
                title={'get started for culture platform'}
                style={{ padding: 6 }}
              >
                <Button
                  type="primary"
                  style={{ minWidth: '120px', width: 'auto', marginLeft: 16 }}
                >
                  Get Started
                </Button>
              </Link>
            </>
          )}
          {/* <LanguageSelector /> */}
          {mobile && <MobileMenuApp app={app} items={items} />}
        </div>
      </div>
    </header>
  );
};

export default Header;
