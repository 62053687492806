import styles from "@buddieshr/styles/components/logoApp.module.scss";
import ByBuddiesHR from "../byBuddiesHR";
import Image from "next/image";

const LogoApp = ({
  className,
  wrapperClassName,
  removeBack = false,
  withText = true,
  app,
  white = false,
  withSubtitle = false,
  removeByBuddies = false,
  wrapperStyle = {},
  style = {},
}) => (
  <div style={wrapperStyle}>
    <div
      className={`${styles.logoLink}${
        wrapperClassName ? ` ${wrapperClassName}` : ""
      }`}
      style={style}
    >
      <div className={`${styles.logoWrapper} ${className || ""}`}>
        <Image
          src={removeBack ? app.logoUrl : app.logoUrlWithBackground}
          alt={`logo ${app.name}`}
          width={40}
          height={40}
        />
      </div>
      {withText && (
        <div
          className={`${styles.logoTextWrapper}${
            white ? ` ${styles.white}` : ""
          }${removeByBuddies ? ` ${styles.logoTextWrapperCentered}` : ""}`}
        >
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              lineHeight: "28px",
            }}
          >
            {app.fullName}
          </div>
          {withSubtitle && (
            <div
              className={`${styles.shortTitle}${
                white ? ` ${styles.shortTitleWhite}` : ""
              }`}
            >
              {app.shortTitle}
            </div>
          )}
          {!removeByBuddies && <ByBuddiesHR white={white} />}
        </div>
      )}
    </div>
  </div>
);

export default LogoApp;
